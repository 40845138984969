import React, { Suspense }                                       from 'react';
import { BrowserRouter, Route, Routes }                          from 'react-router-dom';
import LoadingMessage                                            from 'Component/Common/LoadingMessage/LoadingMessage';
import { connect, MapDispatchToPropsNonObject, MapStateToProps } from 'react-redux';
import { linkHandler }                                           from 'ClientService';
import { IRoute, PrivateRoutes, PublicRoutes }                   from 'AppConfig/Routes';
import App                                                       from 'Component/Application/App';
import { PublicApp }                                             from 'Component/Application/PublicApp/PublicApp';
import { bindActionCreators }                                    from 'redux';
import { IReduxStore }                                           from 'Redux/Store/IReduxStore';
import { isLoggedIn }                                            from 'Redux/Query';
import { logoutUser }                                            from 'Redux/Action';
import { Layout }                                                from 'antd';
import { IsUserLoggedIn }                                        from './IsUserLoggedIn';

interface IDispatchProps {
    logoutUser: () => void,
}

interface IStateProps {
    isLoggedIn: boolean,
}

interface IProps {
    hasNetworkError: boolean,
    isAuthenticationRequest: boolean
}

type TProps = IDispatchProps & IStateProps & IProps & {

};

type TState = {
    isLoggedIn: boolean,
}

class Router extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);

        this.state = {
            isLoggedIn: props.isLoggedIn || false,
        }
    }

    componentDidUpdate(prevProps: TProps, prevState: IStateProps) {
        if(this.props.isLoggedIn !== prevProps.isLoggedIn) {
            this.setState(() => ({ isLoggedIn: this.props.isLoggedIn }));
        }
    }

    addRoutes = (routes: Record<string, IRoute>) => {
        const routesView: React.ReactElement[] = [];

        for(const key in routes){
            const route      = routes[key];
            const routeProps = {
                exact: route.exact || true,
                path: route.path   || linkHandler.get('login'),
                component: route.component || (() => (<div/>))
            };

            if(route.arguments) {
                // noinspection JSAnnotator
                routeProps.path += `/:${route.arguments.join('/:')}`;
            }
            const Component = routeProps.component;

            routesView.push(
                <Route key={`route_${key}`} path={routeProps.path} element={
                    <Suspense fallback={this.renderFallBack()}>
                        {this.props.isLoggedIn ?
                            <App>
                                <Component/>
                            </App> :
                            <PublicApp>
                                <Component/>
                            </PublicApp>
                        }

                    </Suspense>
                }/>
            );
        }

        return routesView;
    }

    renderFallBack = () => {
        if(this.props.isLoggedIn) {
            return (
                <Layout>
                    <LoadingMessage />
                </Layout>
            );
        }
        return <LoadingMessage />;
    }

    render() {
        const { isLoggedIn } = this.props;
        let routesView = this.addRoutes(PublicRoutes);
        if( isLoggedIn ) {
            routesView = [ ...routesView, ...this.addRoutes(PrivateRoutes) ];
        }
        console.log(isLoggedIn, routesView);

        if(this.props.isAuthenticationRequest) {
            return <LoadingMessage />;
        }

        const FallBackComponent = PublicRoutes.login.component;


        return(
            <BrowserRouter>
                <IsUserLoggedIn />
                <Routes>
                    {routesView}
                    <Route
                        path="*"
                        element={
                            <Suspense fallback={this.renderFallBack()}>
                                {/* @ts-ignore */}
                                <PublicApp>
                                    <FallBackComponent />
                                </PublicApp>
                            </Suspense>
                        }
                    />
                </Routes>
            </BrowserRouter>

        );
    }

}

const mapStateToProps: MapStateToProps<IStateProps, IProps, IReduxStore> = (state) => {
    return {
        hasNetworkError: false,
        isLoggedIn: isLoggedIn(state),
        isAuthenticationRequest: state.user.openRequests.includes('AUTHENTICATE_REQUEST'),
    };
}

const mapDispatchToProps: MapDispatchToPropsNonObject<IDispatchProps, IProps> = (dispatch) => {
    return bindActionCreators({
        logoutUser,
    }, dispatch);
};


export default connect(mapStateToProps, mapDispatchToProps)(Router);
