import React from 'react';
import ContentStyle from './Content.style';


type TProps = {
    children: React.ReactNode,
}
export default class Content extends React.Component<TProps> {

    render() {
        return (
            <ContentStyle>
                { this.props.children }
            </ContentStyle>
        );
    }

};
