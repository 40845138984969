import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { Persistor, Store } from "Redux/Config";
import Router from "Component/Application/Router/Router";
import { PersistGate } from 'redux-persist/integration/react'
import Bootstrap from "Redux/Bootstrap";
import 'antd/dist/reset.css';
import "Public/css/global.css";
import moment from "moment";
import "moment-timezone";

moment.tz.setDefault("Europe/Berlin");

const Application = () => (
    <Provider store={ Store }>
        <PersistGate loading={null} persistor={ Persistor }>
            { /* @ts-ignore */}
            <Router />
        </PersistGate>
    </Provider>
)

Bootstrap()
    .then(() => Application())
    .catch(error => console.error(error));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - ReactDOM.createRoot is not yet in the types
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Application />);
