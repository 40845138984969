import React, { ReactNode }  from 'react';
import { Link as ReactLink } from 'react-router-dom';
import Wrapper from './Link.style';
import { linkHandler }  from 'ClientService';

interface IProps {
    to?: string,
    href?: string,
    externalLink?: string,
    args?: {},
    children?: ReactNode,
    className?: string,
    onClick?: (evt: any) => void
}

type TProps = IProps


export default class Link extends React.Component<TProps, {}> {

    render() {

        let link = null;
        if( this.props.to ) {
            link = <ReactLink to={ this.props.to }>{ this.props.children }</ReactLink>;
        }
        if( this.props.href ) {
            link = <ReactLink to={linkHandler.get(this.props.href, this.props.args)}>{ this.props.children }</ReactLink>;
        }
        if( this.props.externalLink ) {
            link = <a href={ `${this.props.externalLink} `} rel="noopener noreferrer" target="_blank">{ this.props.children }</a>
        }

        return(
            <Wrapper { ...this.props }>
                { link }
            </Wrapper>
        );
    }

}
