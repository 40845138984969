import { GlobalState, TGlobalState } from "Redux/Store/Global/GlobalState";
import { Reducer } from "redux";
import { message } from "antd";
import {
    LOGOUT_USER,
    APPLICATION_BOOTSTRAPPED,

    APPEAR_PAGE,

    SHOW_NOTIFICATION,
} from "Redux/Action";
import { linkHandler } from "ClientService"

const initialState = GlobalState.getInitialState();

export const globalReducer: Reducer<TGlobalState> = ( state = initialState, action ) => {
    switch( action.type ) {
        case APPLICATION_BOOTSTRAPPED:
            return {
                ...state,
                updatedAt: Date.now(),
                isApplicationBootstrapped: true
            };

        case APPEAR_PAGE:
            const route: any = linkHandler.findByPageKey(action.payload.page);
            console.log(route);
            return {
                ...state,
                activeSideMenuTab: route?.parent || route?.key,
                updatedAt: Date.now(),
            }

        case SHOW_NOTIFICATION:
            // @ts-ignore
            message[action.payload.notificationType](action.payload.message)
            return {
                ...state,
                updatedAt: Date.now(),
            };

        case LOGOUT_USER:
            return initialState;


        default:
            return state;
    }
};
