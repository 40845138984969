import { IReduxStore } from 'Redux/Store/IReduxStore';
import { ArrayContains } from 'ClientService';
import {
    AUTHENTICATE_REQUEST,
} from 'Redux/Action';

export const isUserLoading = (state: IReduxStore) =>
    ArrayContains(state.user.openRequests, [
        AUTHENTICATE_REQUEST,
    ]);



