import React from "react";
import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

const getRoutePath = (location: any, params: Record<string, string>) => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

export function withRouter<TProps> (Component: any) {
    return (props: TProps) => {
        let location = useLocation();
        let navigate = useNavigate();
        let params   = useParams();

        return (
            /* @ts-ignore */
            <Component
                {...props}
                router={{ location, navigate, params }}
                history={ {
                    replace: (to: string) => { navigate(to, { replace: true }); },
                    goBack: () => { navigate(-1); },
                    push: (to: string) => { navigate(to); }
                } }
                location={ location }
                match={{
                    url: location.pathname,
                    params,
                    path: getRoutePath(location, params as Record<string, string>),
                }}
            />
        );
    }
}

export type RouteComponentProps = {
    router: {
        location: any;
        navigate: (to: string) => void;
        params: Record<string, string>;
    };
    history: {
        replace: (to: string) => void;
        goBack: () => void;
        push: (to: string) => void;
    };
    location: any;
    match: {
        url: string;
        params: Record<string, string>;
        path: string;
    };
}
