import styled from 'styled-components';
import { Layout } from "antd";
import { white, secondaryFontColor } from "Public/css/colors";

export default styled(Layout.Sider) `
    
    width: 256px;
    
    
    .sidebarLogo {
        
        margin-bottom: 10px;
        
        img {
            
            margin: 0 auto;
            display: block;
            padding-top: 22px;
            padding-bottom: 8px;
            width: 154px;
        }
        
        h3 {
            color: ${ white };
            text-align: center;
            font-weight: bold;
            font-size: 22px;
            letter-spacing: 0;
        }
    
    }
    
    .ant-menu {
        background-color: transparent;
        border: none;
    }
    
    .ant-menu-item {
    
        & > span {
            width: 100%;
            display: inline-block;
            
            a {
                display: inline-block;
                width: calc(100% + 32px);
                margin-left: -16px;
                padding-left: 16px;
            }
        }

        &.ant-menu-item-selected {
            background-color: ${ secondaryFontColor };
            width: calc(100% + 1px) !important;
            border-radius: 0 !important;
        }

        &:hover, &:focus, &:active, &:visited {
            background-color: ${ secondaryFontColor };
            width: calc(100% + 1px) !important;
            border-radius: 0 !important;
        }
        
        color: ${ white };
        text-transform: uppercase; 
        margin: 0 !important;
        height: 56px;
        line-height: 56px;
        
        .anticon {
            margin: 0 18px 0 2px;
            font-size: 20px;
        }
        
        a {
          color: ${ white };
        
        }
    }
  
  .menuBadge {
      position: absolute;
      top: 10px;
      left: 30px;

      .ant-badge-count {
          min-width: 16px;
          height: 16px;
          box-sizing: border-box;
          padding: 0;
          line-height: 1.75;
          font-size: 10px;
      }
  }
    
`;
