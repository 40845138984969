import { BaseModel }           from 'Domain/Model/BaseModel/BaseModel';
import { IImage, Image }       from 'Domain/Model/Image/Image';
import { ITag, Tag }           from 'Domain/Model/Tag/Tag';
import { ITranslationOverlay } from 'Domain/Model/Language/Language';

export interface ISpot {
    id:          string,
    name:        string,
    description: string,
    city:        string,
    country:     string,
    postCode:    string,
    address:     string,
    point:       {
        coordinates: [number, number],
    },
    startAt: Date|null,
    endAt: Date|null,
    hidden: boolean,
    images: IImage[],
    tags: ITag[],
    spots: ISpot[],

    overlayTexts?: { field: string, text: string, languageId: string }[],
}

export class Spot extends BaseModel {

    public id: string;
    public name: string;
    public description: string;
    public city: string;
    public country: string;
    public postCode: string;
    public point: {
        coordinates: [number, number],
    };
    public startAt: Date|null;
    public endAt: Date|null;
    public hidden: boolean;
    public address: string;
    public images: Image[];
    public tags: Tag[];
    public spots: Spot[];
    public textOverlay?: Record<string, ITranslationOverlay>;


    constructor(data: ISpot) {
        super();

        this.id          = data.id;
        this.name        = data.name;
        this.description = data.description || '';
        this.city        = data.city;
        this.country     = data.country;
        this.postCode    = data.postCode;
        this.point       = data.point;
        this.startAt     = data.startAt ? new Date(data.startAt) : null;
        this.endAt       = data.endAt ? new Date(data.endAt) : null;
        this.hidden      = data.hidden;
        this.address     = data.address;
        this.images      = data.images?.map(entry => new Image(entry)) || [];
        this.tags        = data.tags?.map(entry => new Tag(entry)) || [];
        this.spots       = data.spots?.map(entry => new Spot(entry)) || [];

        this.textOverlay = {};

        if(data.overlayTexts) {
            data.overlayTexts.forEach(entry => {
                this.textOverlay![entry.languageId] = {
                    ...(this.textOverlay![entry.languageId] || {}),
                    [entry.field]: entry.text,
                };
            })
        }
    }



}
