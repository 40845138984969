import { linkHandler }           from 'ClientService';
import React                     from 'react';
import { useSelector }           from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isLoggedIn }            from 'Redux/Query';
import { PublicRoutes }          from 'AppConfig/Routes';

export const IsUserLoggedIn = () => {
    const location  = useLocation();
    const { pathname } = location;
    const isUserLoggedIn = useSelector(isLoggedIn);

    if(!isUserLoggedIn && pathname.length < 60 && !Object.values(PublicRoutes).map(route => route.path).includes(pathname) ) {
        console.log('return to login');
        return <Navigate to={ linkHandler.get('login') } />
    }

    if(!isUserLoggedIn || (isUserLoggedIn && pathname !== linkHandler.get('login'))) {
        return null;
    }
    return <Navigate to={linkHandler.get('dashboard')} />
}
