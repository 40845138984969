import React from 'react';
import Style from "./PublicApp.style";
import Footer from "Component/Application/Footer/Footer";
import Content from "Component/Application/Content/Content";


type TProps = { children: React.ReactNode };


export const PublicApp = (props: TProps) => {
    return (
        <Style { ...props }>
            <Content>
                { props.children }
            </Content>
            <Footer  />
        </Style>
    );
}

